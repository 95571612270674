import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';

import { ReactTabulator } from 'react-tabulator';

import { Col, Row, Button, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRetweet, faEnvelope } from '@fortawesome/free-solid-svg-icons'


export function DomainList() {

	const [showCreateMailbox, setShowCreateMailbox] = useState( false );
	const [domainData, setDomainData] = useState( false );

  const tabulatorOptions = {
    ajaxURL: 'https://'+process.env.REACT_APP_API_HOST+'/api/domain/list',
    ajaxConfig:{
        method:"POST", //set request type to Position
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"), //set specific content type
        },
    },
    ajaxError: function(xhr, textStatus, errorThrown){
      console.log( 'ajax error' )
    }
  }

	const domainControlFormatter = (cell, formatterParams, onRendered) => {
    createRoot(cell.getElement()).render(
      <>
        <FontAwesomeIcon icon={faRetweet} className="actionIcon" />
				<FontAwesomeIcon icon={faEnvelope} className="actionIcon" />
      </>
    );
  }

  const columns = [
    { title: "Id", field: "id", width: '1<FontAwesomeIcon icon={faRetweet} className="actionIcon" />0%' },
    { title: "Domain", field: "domain" },
		{ title: "Mailboxes", field: "mailboxCount", width: '10%' },
		{ title: "Expiration", field: 'regExpireDate' },
		{ title: "Control", formatter:domainControlFormatter, width: '10%' }
  ];

	const tabulatorRef = useRef(null);

	//console.log( JSON.stringify( tabulatorRef ) )

	useEffect(() => {
		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/domain/list', {
			method:"POST", //set request type to Position
      headers: {
      	'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      }
		})
		.then(d => d.json())
		.then(d => {
			//console.log( d )
			setDomainData( d )
		})
    return () => {};
  }, []);

  return (
    <>
			<Row className="">
        <Col>
          <h3>My Domains</h3>
				</Col>
			</Row>
			<Row>
				<Col className="mx-auto" xs={11}>
					<div className="dataTable">
						{ domainData && domainData.map(domain => (
							<div className="dtRow" key={domain.id}>
								<div className="dtCol tableDomainName text-truncate">{domain.domain}</div>
								<div className="dtCol tableDomainExpire d-none d-lg-block text-truncate">{ new Date(domain.regExpireDate).toDateString() }</div>
								<div className="dtCol tableDomainCount d-none d-lg-block">{domain.mailboxCount}</div>
								<div className="dtCol tableDomainControl">
									<FontAwesomeIcon icon={faRetweet} className="actionIcon" />
        					<FontAwesomeIcon icon={faEnvelope} className="actionIcon" />
								</div>
							</div>
						))}
						{ domainData && !domainData.length &&
              <div className="dtRow">
                <div className="dtCol">No Domains</div>
              </div>
            }
					</div>
				</Col>
			</Row>
    </>
  );
}
