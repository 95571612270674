import { useState } from 'react';
import { Link } from "react-router-dom";

import { Col, Row } from 'react-bootstrap';


function Tos() {

	const coname = 'Pseudonym.mx LLC'

  return (
		<>
			<Row>
				<Col xs={11} className="mx-auto">
        	<h1 class="fw-bold">TERMS OF SERVICE</h1><br/>
					Revision date: Jan 01, 2025<br/><br/>
					{coname} is a Limited Liability Corporation in the state of Florida. All services provided by {coname} may be used for lawful purposes only. Transmission, storage or presentation of any information, data or material in violation of any United States federal, state or city law is prohibited. This includes, but is not limited to: copyrighted material, material we judge to be threatening or obscene, or material protected by trade secret and other statute. The subscriber agrees to indemnify and hold harmless {coname} from any claims resulting from the use of service which damages the subscriber or any other party.<br/>
					<br/>
					<h3>Functional Service</h3>
					In these terms we will refer to service functionality. We define functional service as being able to send email that does not trigger our spam filters or rate limits, and the ability to receive mail. Failure to properly configure DNS will not be considered as breaking the service’s ability to function as sold.<br/>
					<br/>
					<h3>Legitimate Usage</h3>
					The domains that you add to our system should be legitimate, managed by you or your customers, and not related to any spam/scam activity in any way. While we cannot describe in perfect detail everything that qualifies as spam/scam activity, we reserve the right to make a decision about it based on information that we learn. This includes, but may not be limited to, domains that are intended to confuse recipients into thinking you are representing a domain that you do not control by use of misspellings, etc.<br/>
					<br/>
					<h3>Support Treatment / Expectations</h3>
					As a budget provider we do rely on many of our automations to handle basic functions, and requests for manual intervention can take some time (more so when not impacting the functionality of the service). We will not tolerate being rude or threatening to our staff. We will not tolerate threats of chargeback/payment dispute as an attempt to escalate low priority issues that do not stop the service from functioning. Being rude or threatening (including threat of chargeback / payment dispute) will result in immediate account termination.<br/>
					<br/>
					<h3>Server Abuse</h3>
					Any attempt to undermine or cause harm to a server or customer of {coname} is strictly prohibited. As our customer you are responsible for all your accounts. This includes abuse of the server’s CPU and RAM resources to a degree that effects the performance of other customers on your server. Should you violate the Terms of Services outlined within, your account will be cancelled without chance of refund. If you abuse system resources, you will be contacted and offered options for accomodating or reducing usage prior to having your service terminated. Abusive processes, however, will be shut down prior to our attempts to contact you.<br/>
					<br/>
					<h3>Valid DNS Records</h3>
					You are required to have valid SPF and MX records on domains that send through our service. Failure to do so results in reduced sending reputation for your domain and our IP addresses, which can result in us parting ways to ensure high quality sending for our other customers. The SPF and MX records are given to you in an email sent after you pay for service.<br/>
					<br/>
					<h3>Refusal of Service</h3>
					We reserve the right to refuse, cancel or suspend service, at our sole discretion.<br/>
					<br/>
					<h3>Forbidden Services</h3>
We are unable to provide services to the following customers due to consistently negative experiences with bounce rates and spam reports:<br/>
– Leak forums. These are forums where users share compromised accounts for internet services.<br/>
– Adult forums. These are forums where users trade pornography.<br/>
– Any kind of mass mailing campaign to a list of recipients that did not go through a double opt in process<br/>
– Hundreds of emails at a time to qq.com. If you send almost exclusively to qq.com, and hundreds of emails at a time, we are unable to provide you with service.<br/>
– Deceptive use against third party services by creating multiple email accounts to pretend to be multiple users of their service<br/>
– Forward-only service for massive numbers of domains on one account (maximum impact on our servers, minimal cost and spreading out of domains)<br/>
– Sending SMS via email<br/>
– Marketing email<br/>
– “Warmup” services<br/>
- Chinese counterfeit ecommerce websites<br/>
- Anything relating to v2board<br/>
- Email forwarders configured in such a way that they frequently exceed 400 outbound emails per hour<br/>
					<br/>
					<h3>Intended for human consumption</h3>
					Emails sent to other mail services should be intended for human consumption. Please do not flood an excess of automated emails to other mail providers that can easily be described as "No one could possibly be reading all of these on the other side of this transaction." We will take measures to block this excess of junk to protect the reputation of our IP addresses and your domain, because email which isn't intended to be read is by definition email that belongs in the spam folder at the other provider.<br/>
					<br/>
					<h3>Spoofing Senders</h3>
					Under no circumstances are {coname} customers permitted to spoof “From” headers to display a third party service. You are not authorized to directly send as (meaning the domain in the “From” header) any domain that you do not own or manage.<br/>
					<br/>
					<h3>Invalid Recipients</h3>
					If you repeatedly send email to invalid/unroutable recipients, they may be published on our GitHub as part of our open source mail filters. You can find those here.<br/>
					<br/>
					<h3>Sending Limits</h3>
					Each email account will be limited to 400 outbound emails per hour unless a prior agreement has been reached. You may not create additional email accounts for the sole purpose of extending that limit.<br/>
					<br/>
					<h3>Spam Reports</h3>
					If your recipient actively clicks a “Report Spam” (or equivalent) button at their email provider, and we are made aware of it, we will block outbound mail to that address. This is interpreted as claiming that you are sending them email that they do not want, and the potential damage is to the reputation of your domain and our IP addresses. If this occurs unintentionally, you will need to negotiate with the user and ask us to unblock outbound email to them.<br/>
					<br/>
					<h3>Damaging IP Reputation</h3>
					If your email behavior consistently harms the reputation of our IP addresses and causes delayed or failed deliveries for other customers, we reserve the right to cancel your service. This includes repeat failures to secure your accounts by using strong passwords, as password security is your responsibility.<br/>
					<br/>
					<h3>Backups</h3>
					Accounts are backed up frequently in case of hardware failure. Backups are not guaranteed and are provided as a courtesy.<br/>
					<br/>
					<h3>Billing</h3>
					By the Account Activation Date of each month, {coname} shall either:<br/>
					<br/>
					(1) charge the client’s chosen billing method through the associated PayPal subscription; or<br/>
					(2) deliver, by e-mail or regular mail, an invoice in accordance with the applicable Service Fees for services rendered for the current month. When an invoice is delivered to the client, payment shall be remitted to {coname} by no later than the specified payment due date. {coname} shall be entitled to immediately terminate this agreement for client’s failure to make timely payments. You will be provided with an invoice on a monthly basis. All PayPal subscriptions are automatically processed through PayPal and charged to the default or backup payment method that you selected upon signup. It is the client’s responsibility to ensure that they have sufficient credit to cover this transaction. In the event that there is insufficient funding, we will send an e-mail notification, at which point the client must resolve the issue with PayPal within 24 hours. If we do not receive a response within 24 hours, the account, and all accounts under that account plan, may be suspended.<br/>
					<br/>
					<h3>Money back guarantee & refund policy</h3>
					We do not refund cancelled contracts. Exceptions will be made where {coname} is found to have failed to uphold it’s guarantees. In case of policy violations, any and all refunds are forfeit.<br/>
					<br/>
					<h3>Chargebacks</h3>
					We operate a support desk where we intend to resolve issues. Opening a payment dispute with our payment gateway or your funding source is a violation of {coname} policy and will result in service termination and a $50 chargeback fee.<br/>
					<br/>
					<h3>Accurate Information</h3>
					Customers are required to provide accurate billing information on registration, and maintain accurate billing information in our billing portal. Failure to provide accurate information will lead to account suspension, up to account termination.<br/>
					<br/>
					<h3>Cancellation Refunds</h3>
					We DO NOT refund partial monthly fees to accounts. Account cancellations may take up to 72 hours to process and we encourage you to plan accordingly.<br/>
					<br/>
					<h3>Limitation of Liability</h3>
					{coname} shall not be responsible for any claimed damages, including incidental and consequential damages, which may arise from {coname} servers going off-line or being unavailable for any reason whatsoever. Furthermore, {coname} shall not be responsible for any claimed damages, including incidental or consequential damages, resulting from the corruption or deletion of any files from one of {coname}’s servers. All damages shall be limited to the immediate termination of service.<br/>
					<br/>
					<h3>Violations</h3>
					Violations of these policies should be referred to abuse@pseudonym.mx. All complaints will be investigated promptly. Failure to follow any term or condition will be grounds for immediate account termination.<br/>
					<br/>
					<h3>Disclaimer</h3>
					{coname} cannot be held liable for system down time, crashes or data loss. We cannot be held liable for any predicated estimate of profits which a client would have gained if their service was functioning. Certain services provided by {coname} are resold. Thus, certain equipment, routing, software and programming used by {coname} are not directly owned or written by {coname}. Moreover, {coname} holds no responsibility for the use of our clients accounts. Failure to comply with any terms or conditions will result in the automatic deactivation of the account in question. We reserve the right to remove any account, without advance notice for any reason without restitution, as {coname} sees fit.<br/>
					<br/>
					<h3>Server Uptime Guarantee</h3>
					{coname} guarantees 99% service (pop, imap, smtp) uptime on all hosting plans. Should we fail to deliver this for any given calendar month, your account will be refunded a pro-rated amount for the duration of excessive downtime upon request.<br/>
					<br/>
					<h3>Spam</h3>
					Outgoing spam from our servers will not be tolerated. Spam is defined as unsolicited e-mail. All mailing lists must be double opt-in, meaning that clients must sign up and then verify their e-mail address to be added to your mailing list. Violations of this policy will result in service termination. Spam found to be intentional will result in us sharing your name, e-mail address, phone number, address, IP address, signup domain, and PayPal e-mail address with FraudRecord.com. Additionally, intentional unsolicited marketing emails will be billed at a rate of $1 per email.<br/>
					<br/>
					<h3>Account Activation</h3>
					By activating your account with {coname}, you agree to the above policies and disclaimer. Upon requesting activation of an account, you are required to accept these policies, guidelines and disclaimer, and a copy of your acceptance is forwarded along with your activation request to be maintained with your account information.<br/>
					<br/>
					<h3>Privacy</h3>
					With exception of FraudRecord.com in the case of billing chargebacks and intentional spam, we do not share your information with any third party. We do monitor email logs and login activity so that we might be proactive in response to any issues whether technical or security. We do not read your email unless instructed by you to do so. We do review logs that include sender, recipient, and subject when you make a support request.<br/>
					<br/>
					<br/>
					{coname} reserves the right to amend any or all of the above policies, guidelines and disclaimer without notification. We also retain the right to increase any pricing and make changes to our account plans without notification.
				</Col>
    	</Row>
		</>
  );
}

export default Tos;
