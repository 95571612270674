import React from 'react';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
const AsyncTypeahead = withAsync(Typeahead);

const SEARCH_URI = 'https://'+process.env.REACT_APP_API_HOST+'/api/domain/autocomplete/';

export function CreateMailbox( { showCreateMailbox, setShowCreateMailbox } ) {

	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);

	const filterBy = () => true;

	const handleSearch = (query: string) => {
    setIsLoading(true);

    fetch(`${SEARCH_URI}${query}`, {
			headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      }
		}).then((resp) => resp.json())
    .then(r => {
				console.log( r )
        setOptions(r);
        setIsLoading(false);
    });
  };

	const submitCreateMailbox = () => {

		let createData = {
			local_part: document.getElementById('submitCreateMailboxUser').value,
 			domain: document.getElementsByName("domain_name")[0].value,
			domain_id: document.getElementsByName("domain_id")[0].value
		}

		console.log( createData )

		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/mailbox/create', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify( createData )
    }).then(response => {
      console.log( response )
      return response.json()
    }).then(info => {
      //setCurrentBalawnce( info.balance_cents )
			setShowCreateMailbox( false )
    })

	}

	return(
		<>
				<Col xs={6} className="mx-auto">
					<Form>
						<Form.Group className="mb-3">
        			<Form.Control type="text" placeholder="User" id="submitCreateMailboxUser" />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Domain</Form.Label>
              <AsyncTypeahead
								filterBy={filterBy}
      					id="async-example"
      					isLoading={isLoading}
      					labelKey="domain"
      					minLength={3}
      					onSearch={handleSearch}
								options={options}
      					placeholder="Search for a domain..."
								onChange={(selected: Item) => {
									let domainId = selected[0].id
									console.log( domainId )
									document.getElementsByName("domain_id")[0].value = domainId
									document.getElementsByName("domain_name")[0].value = selected[0].domain
								}}
								renderMenuItemChildren={(option: Item) => (
        					<>
          					<span>{option.domain}</span>
        					</>
      					)}
							/>
							<Form.Control type="hidden" name="domain_id" />
							<Form.Control type="hidden" name="domain_name" />
            </Form.Group>
        		<Button variant="primary" onClick={submitCreateMailbox} >
          		Submit
        		</Button>
      		</Form>
				</Col>
		</>
	)

}
