import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Form, Button } from 'react-bootstrap';
import { CurrentUserContext, UpdateUserContext } from './Contexts';

export function OtpVerify() {

	const navigate = useNavigate();

	const {
    	currentUser,
    	setCurrentUser
  } = useContext(CurrentUserContext);

	let submitOtpToken = () => {
		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/user/otp_verify', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify({ token: document.getElementById( 'otpToken' ).value })
    }).then(response => {
      console.log( response )
      return response.json()
    }).then(info => {
			if( info.status ) {
				setCurrentUser( null )
				navigate('/dash')
			}
    })
	}

	return (
		<>
			<Form>
				<Form.Control type="text" placeholder="" id="otpToken" />
				<Button variant="primary" onClick={submitOtpToken} >
          Submit
        </Button>
			</Form>
		</>
	);
}

export function OtpSetup({ triggerVerify, setTriggerVerify }) {

	const [otpQr, setOtpQr] = useState( null );
	//const [showOtpVerify, setShowOtpVerify] = useState( false );

	const {
    currentUser,
    setCurrentUser
  } = useContext(CurrentUserContext);

	useEffect(() => {
		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/user/get_otp_qr', {
     	method: "GET",
     	headers: {
       	"Content-Type": "application/json",
       	'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
     	}
   	}).then(response => {
			return response.json()
   	}).then(info => {
			setOtpQr( info.qrurl )
		}).catch(err => console.log)

		return;
  }, []);

	return (
		<>
			{ currentUser &&
				<>
				{ currentUser.tfa_enabled &&
					<p>2FA Enabled</p>
				}
				{ !currentUser.tfa_enabled &&
					<>
						{ !triggerVerify &&
							<Col>
								<img src={otpQr} />
							</Col>
						}
						{ triggerVerify &&
							<>
								<OtpVerify
									useUserConext={false}
								/>
							</>
						}
					</>
				}
				</>
			}
		</>
	);
}
