import { useId, useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Button, Form } from 'react-bootstrap';
//import { CurrentUserContext } from './Contexts';

function RegisterDomain() {

	const [domainSearhState, setDomainSearchState] = useState( 'presearch' )

	const [domainPrice, setDomainPrice] = useState( 0 )
	//const [domainSearchStatus, setDomainSearchStatus] = useState( false )
	const [domainSearch, setDomainSearch] = useState( false )
	const [domainSearchError, setDomainSearchError] = useState( false )
	const [domainRegistrationStatus, setDomainRegistrationStatus] = useState( false )

	let currentRegDomain = false;

	const domainPriceGet = () => {
		let d = document.getElementById("domainSearchQuery").value;
		setDomainPrice( d )

    fetch('https://'+process.env.REACT_APP_API_HOST+'/api/registry/price', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify({ domain: d })
    }).then(response => {
      return response.json()
    }).then(response => {
			console.log( response )
			setDomainPrice( response.response )
			setDomainSearch( d )
      return response
    })
  }

	const domainSearchSubmit = (event) => {
		event.preventDefault()
		let domain = document.getElementById("domainSearchQuery").value
		console.log('domainSearchSubmit ' + domain)
		currentRegDomain = domain;
		setDomainSearch( domain )
		domainSearchGet();


	}

	const domainSearchGet = () => {
		console.log("domainSearchGet: " + currentRegDomain)

		let data = {
      domain: currentRegDomain
    }

    fetch('https://'+process.env.REACT_APP_API_HOST+'/api/registry/lookup', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify(data)
    }).then(response => {
			if( response.status != 200 ) {
				throw new Error('Domain lookup failure.');
			}
      return response.json()
    }).then(response => {
      console.log( response )

			if( response.response == "taken" ) {
				//document.getElementById("domainSearch").style.visibility = 'visible';
    		//document.getElementById("domainSearchResults").style.visibility = 'hidden';
				//setDomainSearchStatus('taken')
				setDomainRegistrationStatus('Domain is taken')
				setDomainSearchState('done')
				//throw new Error('Domain is taken.')
			}

			//setDomainSearchStatus('available')
			setDomainSearchState('search')
			//document.getElementById("domainSearch").style.visibility = 'hidden';
			//document.getElementById("domainSearchResults").style.visibility = 'visible';

			domainPriceGet( data.domain )

    }).catch( e => {
			setDomainSearchError( String(e) )
			console.log( e )
			return
		})
	}

	const domainRegSubmit = (event) => {
    event.preventDefault();

    let data = {
      domain: domainSearch
    }

		var r = false;

    fetch('https://'+process.env.REACT_APP_API_HOST+'/api/registry/register', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify(data)
    }).then(response => {
			r = response;
			return response.json()
		}).then(response => {
			setDomainSearchState( 'done' )
     	setDomainRegistrationStatus( response.response )
		})
  }

	return (
		<Row>
			<Col xs={12} className="mx-auto">
				<h3>Register a domain on Pseudonym</h3>
				{ domainSearhState == 'presearch' &&
					<>
						<Row>
							<Col xs={11} className="mx-auto">
								<p>
									Before registering a domain on Pseudonym.mx please understand the implications. Unlike registering a domain elsewhere, you do not retain full control of that domain.
									Please refer to out <Link to={'/faq'}>FAQ</Link> for more complete information.
								</p>
								<ul>
									<li>Domains registered on Pseudonym.mx are owned by Pseudonym.mx, regardless of who purchased the domain.</li>
									<li>You may not transfer a domain off Pseudonym.mx</li>
									<li>You will receive notifications when this domain is about to expire. If you do not renew the domain, other users that own email addresses on this domain
									will be given and opportunity to pay for its renewal. If this happens, they become the new "custodian".</li>
								</ul>
							</Col>
						</Row>
						<Row>
							<Col xs={11} className="mx-auto">
								<Form>
          				<Form.Group className="mb-3" controlId="domainSearchQuery">
            				<Form.Label>Domain to register</Form.Label>
            				<Form.Control type="" placeholder="example.com" />
          				</Form.Group>
          				<Button variant="primary" type="submit" onClick={domainSearchSubmit} id="domainSerarchSubmit">
            				Search
          				</Button>
        				</Form>
							</Col>
						</Row>
					</>
				}
				{ domainSearhState == 'search' &&
					<Row id="domainSearchResults" class="text-center mt-4">
        		<h4 class="text-center mb-4">{domainSearch} @ ${domainPrice}</h4>
        		<Button variant="primary" onClick={domainRegSubmit}>
          		Buy domain
        		</Button>
      		</Row>
				}
				{ domainSearhState == 'done' &&
					<Row id="domainRegistrationResults">
        		<p>{domainRegistrationStatus}</p>
					</Row>
				}
				{ domainSearchError &&
					<div>
						{domainSearchError}
					</div>
				}
			</Col>
		</Row>
	)

}

export default RegisterDomain;
