import { Outlet, Navigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';

function Logout() {

	localStorage.setItem("nymJwt", null);

  return <Navigate to="/" />;
}

export default Logout;
