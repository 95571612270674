import { useState } from 'react';
import { Link } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';


function Faq() {

	const coname = 'Pseudonym.mx LLC'

  return (
		<>
			<Row>
				<Col xs={11} className="mx-auto">
        	<h1 class="fw-bold">FAQ</h1><br/>
					<br/>
					<h3>What is Pseudonym.mx?</h3>
					<p>Our goal is to allow anyone with fingertips access to the world of email addresses on funny or vulagr domains. Impress friends, family
						and colleagues with your @gooner.com address. Float to the top of the stack of resumes with your @werewolfgirlfriend.com email!
					</p><br/><br/>
					<h3>Who is behind Pseudonym.mx?</h3>
          <p>
          </p><br/><br/>
				</Col>
    	</Row>
		</>
  );
}

export default Faq;
