import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import { Row, Col, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

export function ShowCurrrentBalance() {

	const [currentBalance, setCurrentBalance] = useState(0);

	useEffect(() => {
		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/user/info', {
     	method: "GET",
     	headers: {
       	"Content-Type": "application/json",
       	'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
     	}
   	}).then(response => {
			return response.json()
   	}).then(info => {
			setCurrentBalance( info.balance_cents )
		}).catch(err => console.log)

		return;
  }, [currentBalance]);

	return (
		<span>
			<b>Current Balance: </b> {currentBalance / 100}
		</span>
	);
}

export function AddFunds() {

	const [amountToAdd, setAmountToAdd] = useState(0);
	const [paymentType, setPaymentType] = useState('btcpay');
	const [showStripe, setShowStripe] = useState( false )

	let navigate = useNavigate();

	function submitAddFunds( e ) {

		let amount = document.getElementById( 'addFundsAmount' ).value;
		setAmountToAdd( amount )
		e.target.setAttribute("disabled", "1")

		let elements;

			fetch('https://'+process.env.REACT_APP_API_HOST+'/api/user/createinvoice', {
      	method: "POST",
      	headers: {
        	"Content-Type": "application/json",
        	'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      	},
				body: JSON.stringify({
					amount: amount,
					type: paymentType
				})
    	}).then(response => {
      	return response.json()
    	}).then(info => {
				switch( info.type ) {
					case 'btcpay':
						window.btcpay.showInvoice( info.data.id )
						break;
					case 'stripe':
						//elements = stripe.elements({ stripeAppearance, info.data.client_secret });
						//const paymentElementOptions = { layout: "accordion" };
						//const paymentElement = elements.create("payment", paymentElementOptions);
						//paymentElement.mount("#payment-element");
						//console.log( info.data )
						//setStripeClientSecret( info.data.client_secret )
						//console.log( stripeClientSecret )
						//setShowStripe( true )
						navigate('/dash/stripe', { state: info.data.client_secret });
						break;
				}
    	})

	}

	const selectPaymentType = ( e ) => {
		setPaymentType( e )
	}

	return (
		<>
			<Row className="py-1">
				<p>Add Funds</p>
				<Col xs={6}>
					<Form>
						<Form.Control type="text" placeholder="$" id="addFundsAmount" />
					</Form>
				</Col>
				<Col xs={3}>
					<DropdownButton
      			title="Pyment Type"
      			id="dropdown-menu-align-right"
    				onSelect={selectPaymentType}
        	>
        		<Dropdown.Item eventKey="btcpay">BTC / LTC</Dropdown.Item>
          	<Dropdown.Item eventKey="stripe">Stripe / CC</Dropdown.Item>
      		</DropdownButton>
				</Col>
				<Col xs={3}>
					<Button variant="primary" onClick={submitAddFunds} >Add Funds</Button>
				</Col>
			</Row>
		</>
	);

}
