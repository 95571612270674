import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';

function Register() {

	const navigate = useNavigate();

	const [un, setUn] = useState('');
	const [pw, setPw] = useState('');
	const [email, setEmail] = useState('');

	const [generalValidation, setGeneralValidation] = useState('');
	const [emailValidation, setEmailValidation] = useState('');

	const usernameChange = (e) => { setUn( e.target.value ) };
	const emailChange = (e) => { setEmail( e.target.value ) };
	const passwordChange = (e) => {
		setPw( e.target.value )
	};


	const handleSubmit = (event) => {
    event.preventDefault();

		let valPass = true;
    let pass1 = document.getElementById('regPass1').value;
    let pass2 = document.getElementById('regPass2').value;

		if( pass1.length < 12 ) {
			valPass = false;
      setEmailValidation('Password is less than 12 characters')
		}

    if( pass1 != pass2 ) {
      valPass = false;
			setEmailValidation('Passwords do not match')
    }

		if( !valPass ) return;

		let data = {
			username: un,
			email: email,
			password: pw
		}

		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/auth/register', {
			method: "POST",
			headers: {
      	"Content-Type": "application/json",
      	// 'Content-Type': 'application/x-www-form-urlencoded',
    	},
			body: JSON.stringify(data)
		}).then(response => {
			console.log( response )
			if (!response.ok) {
				return response.json()
			} else {
				let errResponse = response.json().then(errResponse => {
					setGeneralValidation( errResponse.msg )
				});
			}
		}).then(response => {
			navigate("/login");
		})
  };

  return (
		<Row>
			<Col xs={8} className="mx-auto">
		<div className="login-reg-box">
		<Form onSubmit={handleSubmit}>

			{
				generalValidation &&
				<>
					<Form.Group>
						<Form.Text>
							{generalValidation}
						</Form.Text>
					</Form.Group>
				</>
			}

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Username</Form.Label>
        <Form.Control type="username" placeholder="Username" id="regUsername" onChange={usernameChange} />
      </Form.Group>

			<Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Recovery Email (Optional)</Form.Label>
        <Form.Control type="" placeholder="Email" id="regEmail" onChange={emailChange} />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" id="regPass1" onChange={passwordChange} />
				{ emailValidation &&
					<>
						<Form.Text>
							{emailValidation}
						</Form.Text>
					</>
			}
      </Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword2">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Retype Password" id="regPass2" onChange={passwordChange} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
		</div>
		</Col>
		</Row>
  );
}

export default Register;
