import React from 'react';
import { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'react-tabulator/lib/styles.css'; // required styles
//import 'react-tabulator/lib/css/tabulator.min.css'; // theme
//import 'react-tabulator/lib/css/tabulator_midnight.css';
//import './index.css';

import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';
//import Dash from './Dash';
import Root from './Root';
import Settings from './Settings';
import MyAssets from './MyAssets';
import RegisterDomain from './RegisterDomain';
import PublicDomainList from './PublicDomainList';
import ProtectedRoot from './ProtectedRoot';
import Stripe from './Stripe';
import ErrorPage from "./error-page";
import Tos from './Tos';
import Faq from './Faq';
import Contact from './Contact';

import { OtpVerify } from './2Fa';

//import reportWebVitals from './reportWebVitals';

if( process.env.NODE_ENV === "production" ) ReactGA.initialize("G-N9VLLC6E9T");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
		errorElement: <ErrorPage />,
		children: [
      {
        index: true,
        element: <Home />,
      },
			{
				path: 'login',
				element: <Login />
			},
			{
				path: 'register',
				element: <Register />
			},
			{
				path: 'logout',
				element: <Logout />
			},
			{
				path: 'verify2fa',
				element: <OtpVerify />
			},
			{
				path: 'tos',
				element: <Tos />
			},
			{
        path: 'faq',
        element: <Faq />
      },
			{
				path: 'contact/:type',
				element: <Contact />
			}
    ],
  },
	{
    path: "dash",
    element: <ProtectedRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MyAssets />,
      },
			{
				path: 'alldomains',
				element: <PublicDomainList />
			},
			{
				path: 'settings',
				element: <Settings />
			},
			{
				path: 'register',
				element: <RegisterDomain />
			},
			{
				path: 'myassets',
				element: <MyAssets />
			},
			{
				path: 'stripe',
				element: <Stripe />
			}
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
