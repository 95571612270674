import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { OtpVerify } from './2Fa';

function Login() {

	const navigate = useNavigate();

	const [show2fa, setShow2fa] = useState( false );

	const [un, setUn] = useState('');
	const [pw, setPw] = useState('');
	const [otp, setOtp] = useState( null );

	const usernameChange = (e) => { setUn( e.target.value ) };
	const passwordChange = (e) => { setPw( e.target.value ) };

	const handleSubmit = (event) => {
    event.preventDefault();

		let data = {
			username: un,
			password: pw
		}

		if( document.getElementById('otpToken') ) {
			data.token = document.getElementById('otpToken').value
		}

		fetch('https://'+process.env.REACT_APP_API_HOST+'/api/auth/login', {
			method: "POST",
			headers: {
      	"Content-Type": "application/json",
      	// 'Content-Type': 'application/x-www-form-urlencoded',
    	},
			body: JSON.stringify(data)
		}).then(response => {
			console.log( response )
			return response.json()
		}).then(response => {
			console.log( response )

			if( response.msg === 'ok' ) {
				if( response.token ) {
					localStorage.setItem("nymJwt", response.token);
					navigate("/dash");
				}
			} else if( response.msg === 'tfa' ) {
				//navigate("/dash");
				setShow2fa( true )
			}

		})
  };

  return (
		<>
		{ show2fa &&
			<>
				<Form>
        	<Form.Control type="text" placeholder="" id="otpToken" />
        	<Button variant="primary" onClick={handleSubmit} >
          	Submit
        	</Button>
      	</Form>
			</>
		}
		{ !show2fa &&
			<>
				<Row>
					<Col xs={8} className="mx-auto">
				<div className="login-reg-box">
					<Form onSubmit={handleSubmit}>
      			<Form.Group className="mb-3" controlId="formBasicEmail">
        			<Form.Label>Username</Form.Label>
        			<Form.Control type="" placeholder="Username" onChange={usernameChange} />
      			</Form.Group>

      			<Form.Group className="mb-3" controlId="formBasicPassword">
        			<Form.Label>Password</Form.Label>
        			<Form.Control type="password" placeholder="Password" onChange={passwordChange} />
      			</Form.Group>
      			<Form.Group className="mb-3" controlId="formBasicCheckbox">
        			<Form.Check type="checkbox" label="Check me out" />
      			</Form.Group>
      			<Button variant="primary" type="submit">
        			Submit
      			</Button>
    			</Form>
				</div>
				</Col>
				</Row>
			</>
		}
		</>
  );
}

export default Login;
