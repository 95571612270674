import React from 'react';
import { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';

import { DomainList } from './DomainList';
import { EmailList } from './EmailList';
import { CreateMailbox } from './CreateMailbox';

export function MyAssets() {

	const [showCreateMailbox, setShowCreateMailbox] = useState( false );

  return (
    <>
			{ showCreateMailbox &&
				<CreateMailbox
					showCreateMailbox={showCreateMailbox}
					setShowCreateMailbox={setShowCreateMailbox}
				/>
			}
			{ !showCreateMailbox &&
				<>
					<EmailList
						showCreateMailbox={showCreateMailbox}
          	setShowCreateMailbox={setShowCreateMailbox}
					/>
      		<br /><br />
					<DomainList />
				</>
			}
    </>
  );
}

export default MyAssets
