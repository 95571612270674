import { useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Button, Form } from 'react-bootstrap';

function Home() {
  return (
		<>
			<div class="p-5 mb-4 bg-body-tertiary rounded-3">
      	<div class="container-fluid py-5">
        	<h1 class="display-5 fw-bold">Pseudonym.mx</h1>
        	<p class="col-md-8 fs-4">Register a public domain name with Pseudonym.mx and other can create email addresses on that domain.</p>
      	</div>
    	</div>

			<Row>
      	<Col xs={10} className="mx-auto pt-5">
					<div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
    			  <div class="col d-flex align-items-start">
        			<div class="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          			<svg class="bi" width="1em" height="1em"></svg>
        			</div>
        			<div>
          			<h3 class="fs-2 text-body-emphasis">Email Is Tricky</h3>
          			<p>Let us handle the complexities of SPF, DKIM & IP Reputation.</p>
        			</div>
      			</div>
      			<div class="col d-flex align-items-start">
        			<div class="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          			<svg class="bi" width="1em" height="1em"></svg>
        			</div>
        			<div>
          			<h3 class="fs-2 text-body-emphasis">Featured title</h3>
          			<p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        			</div>
      			</div>
      			<div class="col d-flex align-items-start">
        			<div class="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          			<svg class="bi" width="1em" height="1em"></svg>
        			</div>
        			<div>
          			<h3 class="fs-2 text-body-emphasis">Featured title</h3>
          			<p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        			</div>
      			</div>
    			</div>
				</Col>
			</Row>

			<Row>
			<Col xs={10} className="mx-auto pt-5">
			<div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">Minor</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">$45<small class="text-body-secondary fw-light">/yr</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>5 email addresses</li>
              <li>10 GB of storage</li>
              <li>Email support</li>
              <li>Help center access</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">Major</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">$65<small class="text-body-secondary fw-light">/yr</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Unlimited email addresses</li>
              <li>25 GB of storage</li>
              <li>Priority email support</li>
              <li>Help center access</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-4 rounded-3 shadow-sm border-primary">
          <div class="card-header py-3 text-bg-primary border-primary">
            <h4 class="my-0 fw-normal">Mega</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">$85<small class="text-body-secondary fw-light">/yr</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Unlimited email addresses</li>
              <li>50 GB of storage</li>
              <li>Priority email support</li>
              <li>Help center access</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
		</Col>
		</Row>

		</>
  );
}

export default Home;
