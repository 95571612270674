import React from 'react';
import { useEffect, useState } from 'react';
import debounce from 'debounce';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function PublicDomainList() {

	const [domainData, setDomainData] = useState( false );
	const [searchQuery, setSearchQuery] = useState( '' );

	useEffect(() => {

		let url = 'https://'+process.env.REACT_APP_API_HOST+'/api/domain/'
		url += ( searchQuery.length ) ? 'autocomplete/' + searchQuery : 'public'

    fetch(url, {
      method: ( searchQuery.length ) ? 'GET' : 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      }
    })
    .then(d => d.json())
    .then(d => {
      //console.log( d )
      setDomainData( d )
    })
    return () => {};
  }, [searchQuery]);

	const searchChange = ( e ) => {
		setSearchQuery( e.target.value )
	}

	const debouncedSearchChange = debounce(searchChange, 700);

	return (
    <>
      <Row className="">
        <Col>
          <h3>Publicly available domains</h3>
        </Col>
      </Row>
			<Row className="my-3">
				<Col xs={8} className="mx-auto">
					<Row>
						<Col xs={6}>
          		<input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Search" onChange={debouncedSearchChange} />
        		</Col>
						<Col>
							<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> Include Vulgar
						</Col>
					</Row>
				</Col>
			</Row>
      <Row>
        <Col className="mx-auto" xs={11}>
          <div className="dataTable">
            { domainData && domainData.map(domain => (
              <div className="dtRow" key={domain.id}>
                <div className="dtCol tableDomainName text-truncate">{domain.domain}</div>
                <div className="dtCol tableDomainExpire d-none d-lg-block text-truncate">{ new Date(domain.regExpireDate).toDateString() }</div>
                <div className="dtCol tableDomainCount d-none d-lg-block">{domain.mailboxCount}</div>
                <div className="dtCol tableDomainControl">
                  <FontAwesomeIcon icon={faPlus} className="actionIcon" />
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}


export default PublicDomainList
