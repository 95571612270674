import { useId, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { OtpSetup } from './2Fa';
import { ShowCurrrentBalance, AddFunds } from './Balance';

function Settings() {

	//const [showOtpVerify, setShowOtpVerify] = useState( false );
	const [verifyTrigger, setVerifyTrigger] = useState( false );

	let clickVerifyBtn = () => {
		setVerifyTrigger( true );
	}

  return (
		<Col>
			<h3>Settings</h3>
			<Row className="py-1">
					<Col xs={6} className="d-flex align-items-center">
							To enable 2FA, scan the QRcode to the right and click Verify.
					</Col>
					<Col xs={2} className="d-flex align-items-center">
						<Button variant="primary" onClick={clickVerifyBtn} >Verify</Button>
					</Col>
					<Col xs={4}>
						<OtpSetup
							verifyTrigger={verifyTrigger}
							setVerifyTrigger={setVerifyTrigger}
						/>
					</Col>
			</Row>

			<AddFunds />

		</Col>
  );
}

export default Settings;
